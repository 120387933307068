// import { ActionTypes } from "../constants/action-types";

// // const initialState = {
// //   events: [], // List of conference events
// // };

// // export const UploadlatestconferenceReducer = (state = initialState, action) => {
// //   switch (action.type) {
// //     case 'FETCH_CONFERENCE_EVENTS_SUCCESS':
// //       return {
// //         ...state,
// //         events: action.payload, // Update the state with fetched events
// //       };
// //     default:
// //       return state;
// //   }
// // };
// export const UploadlatestconferenceReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case 'FETCH_CONFERENCE_EVENTS_SUCCESS':
//       return {
//         ...state,
//         events: action.payload, // Update the state with fetched events
//       };
//     case 'FETCH_CONFERENCE_EVENTS_FAILURE':
//       return {
//         ...state,
//         error: action.payload, // Optionally handle error here
//       };
//     default:
//       return state;
//   }
// };


// export default UploadlatestconferenceReducer;

// UploadlatestconferenceReducer.js

// import { ActionTypes } from "../constants/action-types";

// // Define the initial state
// const initialState = {
//   events: [], // List of conference events
//   error: null, // Store error if any
// };

// // Reducer function
// export const UploadlatestconferenceReducer = (state = initialState, action) => {
//   switch (action.type) {
//     // case ActionTypes.FETCH_CONFERENCE_EVENTS_SUCCESS:
//     //   return {
//     //     ...state,
//     //     events: action.payload, // Update the state with fetched events
//     //   };
//     case ActionTypes.FETCH_CONFERENCE_EVENTS_SUCCESS:
//       return {
//         ...state,
//         events: action.payload.data || [], // Ensure we gracefully handle empty payloads (set to empty array)
//         error: null, // Clear any previous error
//       };
//     case ActionTypes.FETCH_CONFERENCE_EVENTS_FAILURE:
//       return {
//         ...state,
//         error: action.payload, // Store the error message if any
//       };
//     default:
//       return state;
//   }
// };

// export default UploadlatestconferenceReducer;
import { ActionTypes } from "../constants/action-types";

// Define the initial state
const initialState = {
  events: [], // List of conference events
  error: null, // Store error if any
};

// Reducer function
export const UploadlatestconferenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_CONFERENCE_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload.events|| [], // Directly access the events array from the payload
        error: null, // Clear any previous error
      };
    case ActionTypes.FETCH_CONFERENCE_EVENTS_FAILURE:
      return {
        ...state,
        error: action.payload, // Store the error message if any
      };
    default:
      return state;
  }
};

export default UploadlatestconferenceReducer;
