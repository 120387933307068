// // import React, { useEffect } from 'react';
// // import { useDispatch, useSelector } from 'react-redux';
// // import Eventdetails from './Eventdetails';
// // import Footer from '../../Footer';
// // import Header from '../../Header';
// // import Card from './Card';
// // import { fetchUpcomingeventwebinarcountdown, fetchUpcomingwebinarEvents } from '../../../redux/actions/action';

// // const EventdtailsWebinar = () => {
// //   const dispatch = useDispatch();
// //   const { countdown, error: countdownError } = useSelector(state => state.uploadcountdown);
// //   const { upcomingEvents, error: eventsError } = useSelector(state => state.uploadevent);

// //   const cards = [
// //     { title: "Days", description: countdown?.days || 0 },
// //     { title: "Hours", description: countdown?.hours || 0 },
// //     { title: "Minutes", description: countdown?.minutes || 0 },
// //     { title: "Seconds", description: countdown?.seconds || 0 },
// //   ];

// //   useEffect(() => {
// //     // Dispatch actions to fetch countdown and upcoming events
// //     dispatch(fetchUpcomingeventwebinarcountdown());
// //     dispatch(fetchUpcomingwebinarEvents());

// //     // Set interval to update countdown every second
// //     const intervalId = setInterval(() => {
// //       dispatch(fetchUpcomingeventwebinarcountdown());
// //       dispatch(fetchUpcomingwebinarEvents());
// //     }, 1000);

// //     // Cleanup interval on component unmount
// //     return () => clearInterval(intervalId);
// //   }, [dispatch]);

// //   return (
// //     <div className="relative bg-gray xl:w-full lg:w-[1000px] md:w-[1180px] w-[749px] px-0 mx-auto overflow-hidden">
// //       <div className="relative mx-auto pt-[118px] font-poppins flex flex-col min-h-screen">
// //         {/* Header */}
// //         <Header />

// //         <div className="relative w-full flex-grow">
// //           <img
// //             src="/Desktop - 2.png"
// //             alt="Event details"
// //             className="w-full h-auto object-cover mt-[-24px] mb-[15px] md:mb-[235px]"
// //           />
// //           <Eventdetails heading="Webinar" />
// //         </div>

// //         {/* Countdown Cards */}
// //         <div className="grid grid-cols-1 gap-5 mb-[1200px] mt-12 items-center md:grid-cols-2 md:ml-[35px] md:gap-[60px] md:mr-[600px] md:mt-[40px] lg:grid-cols-4 lg:gap-[0px] lg:mb-[650px] lg:ml-[-175px] lg:mr-[300px] lg:mt-[20px] xl:grid-cols-4 xl:gap-x-[-130px] xl:ml-[40px] xl:mr-[550px] xl:mt-[50px]">
// //           {cards.map((card, index) => (
// //             <Card key={index} title={card.title} description={card.description} />
// //           ))}
// //         </div>

// //         {/* Upcoming Events Section */}
// //         <div>
// //           <h1 className="text-white ml-[5px] mt-[-550px] mb-[600px] md:mt-[-550px] md:ml-[140px] md:mb-10 lg:absolute top-[700px] lg:ml-[10px] lg:mt-[330px] lg: font-poppins text-29xl xl:mt-[570px] xl:ml-[78px]">
// //             Events 
// //             <span className="ml-1 block h-[8px] mt-2 bg-white w-[155px] lg:ml-0 lg:w-[165px] lg:h-1 xl:h-[0.7px]"></span>
// //           </h1>

// //           <img
// //             src="/Frame 143726269.png"
// //             alt="Image"
// //             className="absolute top-[1700px] ml-[115px] h-[500px] w-[500px] border rounded-xl lg:mt-[-660px] lg:ml-[640px] lg:w-[350px] object-cover md:mt-[-135px] md:ml-[310px] xl:mt-[-453px] xl:w-[550px] xl:ml-[780px]"
// //           />

// //           {/* Conditional Rendering for Upcoming Events */}
// //           <div className="mt-[-550px] ml-2 mb-[140px] md:ml-[140px] md:mt-[15px] md:mb-[105px] lg:mt-[-420px] lg:ml-5 lg:mb-[200px] lg:ml-2 xl:ml-20 xl:mt-[-440px] xl:mb-[200px]">
// //             {upcomingEvents.length === 0 ? (
// //               <div className="text-white text-29xl font-poppins font-bold lg:text-13xl lg:mt-[75px]">
// //                 {/* The wait is nearly over…<br /> we’ve got a big event <span className="block lg:block">coming your way.</span> */}
// //                 The wait is nearly over…<br /> we’ve got a big event coming <span className="block lg:block">your way.</span>

// //               </div>
// //             ) : (
// //               upcomingEvents.map((event, index) => (
// //                 <div key={index} className="mb-[20px] md:mb-[20px] lg:mb-[35px] xl:mb-[-10px]">
// //                   <div className="flex items-center">
// //                     <h2 className="text-white text-29xl mt-[-15px] ml-0 md:mt-[-10px] lg:mt-[-6px] lg:ml-[5px] lg:text-13xl font-poppins xl:mt-[30px] xl:ml-[-5px]">
// //                       {event.eventName}
// //                     </h2>
// //                   </div>
// //                   <div className="ml-[495px] flex flex-col items-start mt-[-130px] md:ml-[600px] lg:ml-[400px] xl:ml-[350px] xl:mt-[-130px]">
// //                     <h2 className="text-white text-17xl font-poppins mt-4 ml-10 md:mt-5 md:ml-[70px] lg:mt-8 lg:ml-[-10px] lg:text-13xl xl:mt-[40px] xl:ml-[90px]">
// //                       {event.dateOfEvent}
// //                     </h2>
// //                     <h2 className="text-white text-17xl font-poppins mt-[-40px] ml-10 md:mt-[-40px] md:ml-[70px] lg:ml-[-10px] xl:mt-[-40px] xl:ml-[90px] lg:text-13xl">
// //                       {event.joiningTime}
// //                     </h2>
// //                   </div>
// //                   <hr className="border-t-2 border-white mt-[-20px] bg-white h-1 md:mt-[-25px] md:ml-0 md:w-[890px] lg:ml-[5px] lg:w-[580px] lg:h-[1px] xl:ml-[-5px] xl:w-[635px] xl:h-[0px] xl:mt-[-30px]" />
// //                 </div>
// //               ))
// //             )}
// //           </div>
// //         </div>

// //         {/* Footer */}
// //         <Footer />
// //       </div>
// //     </div>
// //   );
// // };

// // export default EventdtailsWebinar;


// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import Eventdetails from './Eventdetails';
// import Footer from '../../Footer';
// import Header from '../../Header';
// import Card from './Card';
// import { fetchUpcomingeventwebinarcountdown, fetchUpcomingwebinarEvents } from '../../../redux/actions/action';

// const EventdtailsWebinar = () => {
//   const dispatch = useDispatch();
//   const { countdown, error: countdownError } = useSelector(state => state.uploadcountdown);
//   const { upcomingEvents, error: eventsError, loading } = useSelector(state => state.uploadevent); // Make sure 'loading' is being tracked

//   const cards = [
//     { title: "Days", description: countdown?.days || 0 },
//     { title: "Hours", description: countdown?.hours || 0 },
//     { title: "Minutes", description: countdown?.minutes || 0 },
//     { title: "Seconds", description: countdown?.seconds || 0 },
//   ];

//   useEffect(() => {
//     // Dispatch actions to fetch countdown and upcoming events
//     dispatch(fetchUpcomingeventwebinarcountdown());
//     dispatch(fetchUpcomingwebinarEvents());

//     // Set interval to update countdown every second
//     const intervalId = setInterval(() => {
//       dispatch(fetchUpcomingeventwebinarcountdown());
//       dispatch(fetchUpcomingwebinarEvents());
//     }, 1000);

//     // Cleanup interval on component unmount
//     return () => clearInterval(intervalId);
//   }, [dispatch],1000);

//   return (
//     <div className="relative bg-gray xl:w-full lg:w-[1000px] md:w-[1180px] w-[749px] px-0 mx-auto overflow-hidden">
//       <div className="relative mx-auto pt-[118px] font-poppins flex flex-col min-h-screen">
//         {/* Header */}
//         <Header />

//         <div className="relative w-full flex-grow">
//           <img
//             src="/Desktop - 2.png"
//             alt="Event details"
//             className="w-full h-auto object-cover mt-[-24px] mb-[15px] md:mb-[235px]"
//           />
//           <Eventdetails heading="Webinar" />
//         </div>

//         {/* Countdown Cards */}
//         <div className="grid grid-cols-1 gap-5 mb-[1200px] mt-12 items-center md:grid-cols-2 md:ml-[35px] md:gap-[60px] md:mr-[600px] md:mt-[40px] lg:grid-cols-4 lg:gap-[0px] lg:mb-[650px] lg:ml-[-175px] lg:mr-[300px] lg:mt-[20px] xl:grid-cols-4 xl:gap-x-[-130px] xl:ml-[40px] xl:mr-[550px] xl:mt-[50px]">
//           {cards.map((card, index) => (
//             <Card key={index} title={card.title} description={card.description} />
//           ))}
//         </div>

//         {/* Upcoming Events Section */}
//         <div>
//           <h1 className="text-white ml-[5px] mt-[-550px] mb-[600px] md:mt-[-550px] md:ml-[140px] md:mb-10 lg:absolute top-[700px] lg:ml-[10px] lg:mt-[330px] lg: font-poppins text-29xl xl:mt-[570px] xl:ml-[78px]">
//             Events 
//             <span className="ml-1 block h-[8px] mt-2 bg-white w-[155px] lg:ml-0 lg:w-[165px] lg:h-1 xl:h-[0.7px]"></span>
//           </h1>

//           <img
//             src="/Frame 143726269.png"
//             alt="Image"
//             className="absolute top-[1700px] ml-[115px] h-[500px] w-[500px] border rounded-xl lg:mt-[-660px] lg:ml-[640px] lg:w-[350px] object-cover md:mt-[-135px] md:ml-[310px] xl:mt-[-453px] xl:w-[550px] xl:ml-[780px]"
//           />

//           {/* Conditional Rendering for Upcoming Events */}
//           <div className="mt-[-550px] ml-2 mb-[140px] md:ml-[140px] md:mt-[15px] md:mb-[105px] lg:mt-[-420px] lg:ml-5 lg:mb-[200px] lg:ml-2 xl:ml-20 xl:mt-[-440px] xl:mb-[200px]">
//             {loading ? ( // Show loading indicator while data is being fetched
//               <div className="text-white text-29xl font-poppins font-bold lg:text-13xl lg:mt-[75px]">
//                 Loading events...
//               </div>
//             ) : upcomingEvents.length === 0 ? (
//               <div className="text-white text-29xl font-poppins font-bold lg:text-13xl lg:mt-[75px]">
//                 The wait is nearly over…<br /> we’ve got a big event coming <span className="block lg:block">your way.</span>
//               </div>
//             ) : (
//               upcomingEvents.map((event, index) => (
//                 <div key={index} className="mb-[20px] md:mb-[20px] lg:mb-[35px] xl:mb-[-10px]">
//                   <div className="flex items-center">
//                     <h2 className="text-white text-29xl mt-[-15px] ml-0 md:mt-[-10px] lg:mt-[-6px] lg:ml-[5px] lg:text-13xl font-poppins xl:mt-[30px] xl:ml-[-5px]">
//                       {event.eventName}
//                     </h2>
//                   </div>
//                   <div className="ml-[495px] flex flex-col items-start mt-[-130px] md:ml-[600px] lg:ml-[400px] xl:ml-[350px] xl:mt-[-130px]">
//                     <h2 className="text-white text-17xl font-poppins mt-4 ml-10 md:mt-5 md:ml-[70px] lg:mt-8 lg:ml-[-10px] lg:text-13xl xl:mt-[40px] xl:ml-[90px]">
//                       {event.dateOfEvent}
//                     </h2>
//                     <h2 className="text-white text-17xl font-poppins mt-[-40px] ml-10 md:mt-[-40px] md:ml-[70px] lg:ml-[-10px] xl:mt-[-40px] xl:ml-[90px] lg:text-13xl">
//                       {event.joiningTime}
//                     </h2>
//                   </div>
//                   <hr className="border-t-2 border-white mt-[-20px] bg-white h-1 md:mt-[-25px] md:ml-0 md:w-[890px] lg:ml-[5px] lg:w-[580px] lg:h-[1px] xl:ml-[-5px] xl:w-[635px] xl:h-[0px] xl:mt-[-30px]" />
//                 </div>
//               ))
//             )}
//           </div>
//         </div>

//         {/* Footer */}
//         <Footer />
//       </div>
//     </div>
//   );
// };

// export default EventdtailsWebinar;


import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Eventdetails from './Eventdetails';
import Footer from '../../Footer';
import Header from '../../Header';
import Card from './Card';
import { fetchUpcomingeventwebinarcountdown, fetchUpcomingwebinarEvents } from '../../../redux/actions/action';

const EventdtailsWebinar = () => {
  const dispatch = useDispatch();
  const { countdown, error: countdownError } = useSelector(state => state.uploadcountdown);
  const { upcomingEvents, error: eventsError, loading } = useSelector(state => state.uploadevent); // Make sure 'loading' is being tracked

  const cards = [
    { title: "Days", description: countdown?.days || 0 },
    { title: "Hours", description: countdown?.hours || 0 },
    { title: "Minutes", description: countdown?.minutes || 0 },
    { title: "Seconds", description: countdown?.seconds || 0 },
  ];

  useEffect(() => {
    // Dispatch actions to fetch countdown and upcoming events
    dispatch(fetchUpcomingeventwebinarcountdown());
    dispatch(fetchUpcomingwebinarEvents());

    // Set interval to update countdown every second
    const intervalId = setInterval(() => {
      dispatch(fetchUpcomingeventwebinarcountdown());
      dispatch(fetchUpcomingwebinarEvents());
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [dispatch]);

  return (
    <div className="relative bg-gray xl:w-full lg:w-[1000px] md:w-[1180px] w-[749px] px-0 mx-auto overflow-hidden">
      <div className="relative mx-auto pt-[118px] font-poppins flex flex-col min-h-screen">
        {/* Header */}
        <Header />

        <div className="relative w-full flex-grow">
          <img
            src="/Desktop - 2.png"
            alt="Event details"
            className="w-full h-auto object-cover mt-[-24px] mb-[15px] md:mb-[235px]"
          />
          <Eventdetails heading="Webinar" />
        </div>

        {/* Countdown Cards */}
        <div className="grid grid-cols-1 gap-5 mb-[1200px] mt-12 items-center md:grid-cols-2 md:ml-[35px] md:gap-[60px] md:mr-[600px] md:mt-[40px] lg:grid-cols-4 lg:gap-[0px] lg:mb-[650px] lg:ml-[-175px] lg:mr-[300px] lg:mt-[20px] xl:grid-cols-4 xl:gap-x-[-130px] xl:ml-[40px] xl:mr-[550px] xl:mt-[50px]">
          {cards.map((card, index) => (
            <Card key={index} title={card.title} description={card.description} />
          ))}
        </div>

        {/* Upcoming Events Section */}
        <div>
          <h1 className="text-white ml-[5px] mt-[-550px] mb-[600px] md:mt-[-550px] md:ml-[140px] md:mb-10 lg:absolute top-[700px] lg:ml-[10px] lg:mt-[330px] lg: font-poppins text-29xl xl:mt-[570px] xl:ml-[78px]">
            Events 
            <span className="ml-1 block h-[8px] mt-2 bg-white w-[155px] lg:ml-0 lg:w-[165px] lg:h-1 xl:h-[0.7px]"></span>
          </h1>

          <img
            src="/Frame 143726269.png"
            alt="Image"
            className="absolute top-[1700px] ml-[115px] h-[500px] w-[500px] border rounded-xl lg:mt-[-660px] lg:ml-[640px] lg:w-[350px] object-cover md:mt-[-135px] md:ml-[310px] xl:mt-[-453px] xl:w-[550px] xl:ml-[780px]"
          />

          {/* Conditional Rendering for Upcoming Events */}
          <div className="mt-[-550px] ml-2 mb-[140px] md:ml-[140px] md:mt-[15px] md:mb-[105px] lg:mt-[-420px] lg:ml-5 lg:mb-[200px] lg:ml-2 xl:ml-20 xl:mt-[-440px] xl:mb-[200px]">
            {loading ? ( // Show loading indicator while data is being fetched
              <div className="text-white text-29xl font-poppins font-bold lg:text-13xl lg:mt-[75px]">
                Loading events...
              </div>
            ) : upcomingEvents.length === 0 ? (
              <div className="text-white text-29xl font-poppins font-bold lg:text-13xl lg:mt-[75px]">
                The wait is nearly over…<br /> we’ve got a big event coming <span className="block lg:block">your way.</span>
              </div>
            ) : (
              upcomingEvents.map((event, index) => (
                <div key={index} className="mb-[20px] md:mb-[20px] lg:mb-[35px] xl:mb-[-10px]">
                  <div className="flex items-center">
                    <h2 className="text-white text-29xl mt-[-15px] ml-0 md:mt-[-10px] lg:mt-[-6px] lg:ml-[5px] lg:text-13xl font-poppins xl:mt-[30px] xl:ml-[-5px]">
                      {event.eventName}
                    </h2>
                  </div>
                  <div className="ml-[495px] flex flex-col items-start mt-[-130px] md:ml-[600px] lg:ml-[400px] xl:ml-[350px] xl:mt-[-130px]">
                    <h2 className="text-white text-17xl font-poppins mt-4 ml-10 md:mt-5 md:ml-[70px] lg:mt-8 lg:ml-[-10px] lg:text-13xl xl:mt-[40px] xl:ml-[90px]">
                      {event.dateOfEvent}
                    </h2>
                    <h2 className="text-white text-17xl font-poppins mt-[-40px] ml-10 md:mt-[-40px] md:ml-[70px] lg:ml-[-10px] xl:mt-[-40px] xl:ml-[90px] lg:text-13xl">
                      {event.joiningTime}
                    </h2>
                  </div>
                  <hr className="border-t-2 border-white mt-[-20px] bg-white h-1 md:mt-[-25px] md:ml-0 md:w-[890px] lg:ml-[5px] lg:w-[580px] lg:h-[1px] xl:ml-[-5px] xl:w-[635px] xl:h-[0px] xl:mt-[-30px]" />
                </div>
              ))
            )}
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </div>
  );
};

export default EventdtailsWebinar;
