export const ActionTypes = {
  SET_STATES: "SET_STATES",
  SET_CITIES: "SET_CITIES",
  SET_HIGHER_EDUCATION: "SET_HIGHER_EDUCATION",
  SUBMIT_FORM_SUCCESS: "SUBMIT_FORM_SUCCESS",
  RESET_FORM: "RESET_FORM",
  ADMIN_LOGIN_SUCCESS: "ADMIN_LOGIN_SUCCESS",
  ADMIN_LOGIN_FAILURE: "ADMIN_LOGIN_FAILURE",
  FETCH_STUDENTS_SUCCESS: "FETCH_STUDENTS_SUCCESS",
  FETCH_STUDENTS_ERROR: "FETCH_STUDENTS_ERROR",
  SEND_EMAIL_SUCCESS: "SEND_EMAIL_SUCCESS",
  SEND_EMAIL_FAILURE: "SEND_EMAIL_FAILURE",
  UPDATE_EMAIL_STATUS: "UPDATE_EMAIL_STATUS",
  EDIT_STUDENT: "EDIT_STUDENT",
  UPLOAD_STATE: "UPLOAD_STATE",
  EDIT_STATE: "EDIT_STATE",
  FETCH_STATE_BY_ID: "FETCH_STATE_BY_ID",
  DEACTIVATE_STATE_SUCCESS: "DEACTIVATE_STATE_SUCCESS",

  UPLOAD_CITY: "UPLOAD_CITY",
  EDIT_CITY: "EDIT_CITY",
  FETCH_CITY: "FETCH_CITY",
  FETCH_CITY_BY_ID: "FETCH_CITY_BY_ID",
  DEACTIVATE_CITY_SUCCESS: "DEACTIVATE_CITY_SUCCESS",

  UPLOAD_EDUCATION: "UPLOAD_EDUCATION",
  EDIT_EDUCATION: "EDIT_EDUCATION",
  FETCH_EDUCATION_BY_ID: "FETCH_EDUCATION_BY_ID",
  DEACTIVATE_EDUCATION_SUCCESS: "DEACTIVATE_EDUCATION_SUCCESS",
  SET_ROLES: "SET_ROLES",
  SUBMIT_USER_FORM_SUCCESS: "SUBMIT_USER_FORM_SUCCESS",
  SUBMIT_USER_FORM_ERROR: "SUBMIT_USER_FORM_ERROR",

  FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",
  FETCH_USERS_ERROR: "FETCH_USERS_ERROR",

  FETCH_MARK: "FETCH_MARK",
  UPLOAD_MARK: "UPLOAD_MARK",
  FETCH_STUDENT_MARK: "FETCH_STUDENT_MARK",
  EMAIL_SENT_SUCCESS: "EMAIL_SENT_SUCCESS",
  EMAIL_SENT_FAILURE: "EMAIL_SENT_FAILURE",
  EMAIL_SENT_SUCCESS: "EMAIL_SENT_SUCCESS",
  EMAIL_SENT_FAILURE: "EMAIL_SENT_FAILURE",
  UPLOAD_PAYMENT: "UPLOAD_PAYMENT",
  FETCH_STUDENT: "FETCH_STUDENT",
  POPUP_EMAIL_SENT_SUCCESS: "POPUP_EMAIL_SENT_SUCCESS",
  POPUP_EMAIL_SENT_FAILURE: "POPUP_EMAIL_SENT_FAILURE",
  DEACTIVATE_STUDENT_SUCCESS: "DEACTIVATE_STUDENT_SUCCESS",
  DEACTIVATE_QUALIFIED_STUDENT_SUCCESS: "DEACTIVATE_QUALIFIED_STUDENT_SUCCESS",
  DEACTIVATE_ROLE_SUCCESS: "DEACTIVATE_ROLE_SUCCESS",
  UPLOAD_ROLE: "UPLOAD_ROLE",

  ADD_EVENT_REQUEST: "ADD_EVENT_REQUEST",
  ADD_EVENT_SUCCESS: "ADD_EVENT_SUCCESS",
  ADD_EVENT_FAILURE: "ADD_EVENT_FAILURE",

  FETCH_EVENTS_REQUEST: "FETCH_EVENTS_REQUEST",
  FETCH_EVENTS_SUCCESS: "FETCH_EVENTS_SUCCESS",
  FETCH_EVENTS_FAILURE: "FETCH_EVENTS_FAILURE",

  UPDATE_EVENT_REQUEST: "UPDATE_EVENT_REQUEST",
  UPDATE_EVENT_SUCCESS: "UPDATE_EVENT_SUCCESS",
  UPDATE_EVENT_FAILURE: "UPDATE_EVENT_FAILURE",

  DELETE_EVENT_REQUEST: "DELETE_EVENT_REQUEST",
  DELETE_EVENT_SUCCESS: "DELETE_EVENT_SUCCESS",
  DELETE_EVENT_FAILURE: "DELETE_EVENT_FAILURE",

  OTP_SENT_SUCCESS: "OTP_SENT_SUCCESS",
  OTP_SENT_FAILURE: "OTP_SENT_FAILURE",

  RESEND_OTP_REQUEST: "RESEND_OTP_REQUEST",
  RESEND_OTP_SUCCESS: "RESEND_OTP_SUCCESS",
  RESEND_OTP_FAILED: "RESEND_OTP_FAILED",

  UPLOAD_OTP_VERIFICATION_REQUEST: "UPLOAD_OTP_VERIFICATION_REQUEST",
  UPLOAD_OTP_VERIFICATION_SUCCESS: "UPLOAD_OTP_VERIFICATION_SUCCESS",
  UPLOAD_OTP_VERIFICATION_FAILURE: "UPLOAD_OTP_VERIFICATION_FAILURE",

  UPDATE_PASSWORD_SUCCESSFULLY: "UPDATE_PASSWORD_SUCCESSFULLY",
  UPDATE_PASSWORD_FAILED: "UPDATE_PASSWORD_FAILED",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  FETCH_EVENT_BY_ID: "FETCH_EVENT_BY_ID",
  PROFILE_UPDATE_ERROR: "PROFILE_UPDATE_ERROR",
  PROFILE_UPDATE_SUCCESS: "PROFILE_UPDATE_SUCCESS",
  ADMIN_LOGOUT_SUCCESS: "ADMIN_LOGOUT_SUCCESS",
  UPLOAD_PARTICIPANT: "UPLOAD_PARTICIPANT",
  FETCH_PARTICIPANT: "FETCH_PARTICIPANT",

  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_FAIL: "EDIT_USER_FAIL",

  // DEACTIVATE_USER_SUCCESS:'DEACTIVATE_USER_SUCCESS',
  // DEACTIVATE_USER_FAIL:'DEACTIVATE_USER_FAIL',

  DEACTIVATE_ROLE_SUCCESS: 'DEACTIVATE_ROLE_SUCCESS',
  DEACTIVATE_ROLE_FAILURE: 'DEACTIVATE_ROLE_FAILURE',

  SEND_FEEDBACK_FORM_REQUEST: 'SEND_FEEDBACK_FORM_REQUEST',
  SEND_FEEDBACK_FORM_SUCCESS: 'SEND_FEEDBACK_FORM_SUCCESS',
  SEND_FEEDBACK_FORM_FAILURE: 'SEND_FEEDBACK_FORM_FAILURE',

  RECEIVE_FEEDBACK_REQUEST: 'RECEIVE_FEEDBACK_REQUEST',
  RECEIVE_FEEDBACK_SUCCESS: 'RECEIVE_FEEDBACK_SUCCESS',
  RECEIVE_FEEDBACK_FAILURE: 'RECEIVE_FEEDBACK_FAILURE',

  FETCH_FEEDBACK: 'FETCH_FEEDBACK',
  FETCH_CERTIFICATE: 'FETCH_CERTIFICATE',
  FETCH_ALL_CERTIFICATE: 'FETCH_ALL_CERTIFICATE',

  FETCH_COUNTDOWN: "FETCH_COUNTDOWN",
  COUNTDOWN_ERROR: "COUNTDOWN_ERROR",

  FETCH_UPCOMING_EVENTS: 'FETCH_UPCOMING_EVENTS',
  FETCH_EVENTS_ERROR: 'FETCH_EVENTS_ERROR',

  UPLOAD_EVENT_IMAGE: 'UPLOAD_EVENT_IMAGE',
  FETCH_EVENT_IMAGE: 'FETCH_EVENT_IMAGE',

  FETCH_CONFERENCE_EVENTS_SUCCESS: 'FETCH_CONFERENCE_EVENTS_SUCCESS',
  FETCH_CONFERENCE_EVENTS_FAILURE: 'FETCH_CONFERENCE_EVENTS_FAILURE',
  FETCH_WEBINAR_EVENTS_SUCCESS: 'FETCH_WEBINAR_EVENTS_SUCCESS',
  FETCH_WEBINAR_EVENTS_FAILURE: 'FETCH_WEBINAR_EVENTS_FAILURE',
  FETCH_WORKSHOP_EVENTS_SUCCESS: 'FETCH_WORKSHOP_EVENTS_SUCCESS',
  FETCH_WORKSHOP_EVENTS_FAILURE:'FETCH_WORKSHOP_EVENTS_FAILURE'
}