import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import EventSection from "./EventSection";
import UpcomingEvents from "./UpcomingEvents";


import Gallery from "./Gallery";

const EventHomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="relative bg-gray md:w-1000 w-[1200px]  min-h-screen overflow-hidden xl:w-full lg:w-[1500px] ">
      <Header />
      <main className=" container mx-auto   lg:px-10 lg:py-0 ">
        <EventSection />
        <UpcomingEvents />
        <Gallery />
      </main>
      <Footer />
    </div>
  );
};

export default EventHomePage;
