import React from "react";

const upcomingeventHeading=({ heading, className = ''})=>{
    return(
<div>
    {/* <h1 className="text-white py-20 px-[100px] text-xl font-normal">{heading}</h1> */}
    <h2 className={`text-29xl font-bold text-white font-normal ${className}`}>{heading}</h2>

</div>
    );
};

export default upcomingeventHeading;