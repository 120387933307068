import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import UpcomingEventHeading from './upcomingeventHeading';
import { fetchConferenceEvents } from "../../redux/actions/action";
import { fetchWebinarEvents } from "../../redux/actions/action";
import { fetchWorkshopEvents } from "../../redux/actions/action";

const UpcomingEvent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const viewmoreclick1 = (id) => {
    navigate(`/eventdetails/1`);
  };
  const viewmoreclick2 = (id) => {
    navigate(`/eventdetails/2`);
  };
  const viewmoreclick3 = (id) => {
    navigate(`/eventdetails/3`);
  };

  const conferenceEvents = useSelector((state) => state.latestconference.events || []);
  const webinarEvents = useSelector((state) => state.latestwebinar.events || []);
  const workshopEvents = useSelector((state) => state.latestworkshop.events || []);

  useEffect(() => {
    dispatch(fetchWebinarEvents());
    dispatch(fetchWorkshopEvents());
    dispatch(fetchConferenceEvents());
    const intervalId = setInterval(() => {
      dispatch(fetchWebinarEvents());
      dispatch(fetchWorkshopEvents());
      dispatch(fetchConferenceEvents());
    }, 1000);
    return () => clearInterval(intervalId);
  }, [dispatch]);

  return (
    <div>
      <h1 className="mt-[0px] ml-16 text-white font-bold text-29xl md:text-29xl md:ml-[15px] lg:text-17xl xl:text-17xl whitespace-nowrap md:mt-[80px] lg:mt-[5px] lg:ml-[-100px] lg:mb-[20px] xl:ml-[40px]">
        Upcoming Events
      </h1>
      <div className="mb-20 lg:mb-[90px] xl:mb-[0px]">
        <UpcomingEventHeading
          heading="Conference on Future"
          className="mt-[10px] px-[100px] text-29xl md:text-29xl md:ml-[-50px] lg:text-17xl lg:ml-[-200px] lg:mt-[20px] xl:text-3xl xl:ml-[-20px] xl:px-[-20px] whitespace-nowrap"
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-[85px] mt-[-60px] md:ml-[-50px] lg:ml-[-200px] lg:mt-[-50px] xl:ml-[-20px] xl:py-[10px] min-h-[300px] flex items-start">
          {Array.isArray(conferenceEvents) && conferenceEvents.length > 0 ? (
            conferenceEvents.map((event) => (
              <div
                key={event.id}
                className="text-white p-4 sm:text-lg md:text-xl lg:text-xl rounded shadow"
                onClick={() => viewmoreclick1(event.id)}
              >
                <h3 className="text-29xl font-normal whitespace-nowrap lg:text-17xl xl:text-3xl">{event.eventName}</h3>
                <p className="text-29xl whitespace-nowrap lg:text-17xl xl:text-3xl">{event.dateOfEvent}</p>
                <button
                  className="mt-10 text-17xl bg-white whitespace-nowrap lg:mt-[35px] md:text-xl lg:text-xl text-black py-3 px-5 font-bold rounded-2xl"
                  onClick={() => viewmoreclick1(event.id)}
                >
                  View More
                </button>
              </div>
            ))
          ) : (
            <div className="flex justify-center items-center w-full">
              <button
                className=" mt-[250px] ml-[-750px] md:mt-[200px] md:ml-[-20px] md:mt-[250px] xl:mt-[170px] 2xl:ml-[-250px] text-17xl bg-white lg:mt-[235px] lg:ml-[-150px] whitespace-nowrap md:text-xl lg:text-xl text-black py-3 px-5 font-bold rounded-2xl"
                onClick={() => viewmoreclick1()}
              >
                View More
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="mb-[0px] lg:mb-0 xl:mb-[0px]">
        <UpcomingEventHeading
          heading="Webinar on Future"
          className="mt-10 px-[100px] md:ml-[-50px] lg:mt-[-410px] lg:px-[420px] lg:text-17xl xl:text-3xl xl:mt-[-310px] xl:ml-[20px] whitespace-nowrap"
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-[85px] mt-[-28px] md:ml-[-50px] lg:px-[430px] lg:mt-[-50px] xl:py-[15px] xl:px-[490px] min-h-[300px] flex items-start">
          {Array.isArray(webinarEvents) && webinarEvents.length > 0 ? (
            webinarEvents.map((event) => (
              <div
                key={event.id}
                className="text-white p-4 sm:text-lg md:text-xl lg:text-xl rounded shadow"
                onClick={() => viewmoreclick2(event.id)}
              >
                <p className="text-29xl whitespace-nowrap lg:text-17xl xl:text-3xl">{event.eventName}</p>
                <p className="text-29xl whitespace-nowrap lg:text-17xl xl:text-3xl">{event.dateOfEvent}</p>
                <button
                  className="mt-10 text-17xl bg-white whitespace-nowrap md:text-xl lg:mt-[20px] lg:ml-[-10px] xl:mt-[25px] lg:text-xl text-black py-3 px-5 font-bold rounded-2xl"
                  onClick={() => viewmoreclick2(event.id)}
                >
                  View More
                </button>
              </div>
            ))
          ) : (
            <div className="flex justify-center items-center w-full ">
              <button
                className=" mt-[200px] ml-[-760px] lg:ml-[80px] lg:mt-[230px]  md:ml-[-20px] xl:mt-[160px] xl:ml-[50px] 2xl:ml-[-20px] text-17xl bg-white whitespace-nowrap md:text-xl lg:text-xl text-black py-3 px-5 font-bold rounded-2xl"
                onClick={() => viewmoreclick2()}
              >
                View More
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="mb-[20px] lg:mb-0 xl:mb-[-90px]">
        <UpcomingEventHeading
          heading="Workshop on Future"
          className="px-[100px] md:ml-[-50px] mt-10 lg:mt-[-320px] lg:px-[850px] lg:text-17xl xl:text-3xl xl:mt-[-320px] xl:ml-[-50px] whitespace-nowrap"
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-[85px] md:ml-[-50px] mt-[-28px] lg:px-[850px] lg:mt-[-50px] xl:py-[12px] xl:ml-[-60px] min-h-[300px] flex items-start">
          {Array.isArray(workshopEvents) && workshopEvents.length > 0 ? (
            workshopEvents.map((event) => (
              <div
                key={event.id}
                className="text-white p-4 sm:text-lg md:text-xl lg:text-xl rounded shadow"
                onClick={() => viewmoreclick3(event.id)}
              >
                <p className="text-29xl whitespace-nowrap lg:text-17xl xl:text-3xl">{event.eventName}</p>
                <p className="text-29xl whitespace-nowrap lg:text-17xl xl:text-3xl">{event.dateOfEvent}</p>
                <button
                  className="mt-10 text-17xl bg-white whitespace-nowrap lg:mt-[10px] xl:mt-[25px] md:text-xl lg:text-xl text-black py-3 px-5 font-bold rounded-2xl"
                  onClick={() => viewmoreclick3(event.id)}
                >
                  View More
                </button>
              </div>
            ))
          ) : (
            <div className="flex justify-center items-center w-full">
              <button
                className=" mt-[200px] ml-[-760px] md:ml-[-20px] md:mt-[250px] xl:mt-[160px] 2xl:ml-[180px]  text-17xl bg-white whitespace-nowrap md:text-xl lg:text-xl  lg:mt-[230px] lg:ml-[150px]  text-black py-3 px-5 font-bold rounded-2xl"
                onClick={() => viewmoreclick3()}
              >
                View More
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpcomingEvent;
