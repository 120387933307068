import { ActionTypes } from "../constants/action-types";

// Define the initial state
const initialState = {
  events: [], // List of conference events
  error: null, // Store error if any
};

// Reducer function
export const UploadlatestworkshopReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_WORKSHOP_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload.events || [], // Directly access the events array from the payload
        error: null, // Clear any previous error
      };
    case ActionTypes.FETCH_WORKSHOP_EVENTS_FAILURE:
      return {
        ...state,
        error: action.payload, // Store the error message if any
      };
    default:
      return state;
  }
};

export default UploadlatestworkshopReducer;
