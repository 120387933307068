import React from 'react';
import { useNavigate } from 'react-router-dom';

function Gallery() {
  const navigate = useNavigate();

  const images = [
    { src: "instructor1.jpg", label: "Conference", position: "below" },
    { src: "instructor1.jpg", label: "Webinar", position: "above" },
    { src: "instructor1.jpg", label: "Workshop", position: "below" },
    { src: "instructor1.jpg", label: "Hackathon", position: "above" },
  ];

  return (
    <section id="gallery" className="my-8 px-4 sm:px-6 md:px-8 lg:px-14">
      <h2 className="text-29xl sm:text-13xl md:text-29xl lg:text-17xl font-bold mb-4 text-white ml-10 md:ml-[-30px] lg:ml-[-150px] xl:ml-[-20px]">Gallery</h2>
      <p className="text-29xl ml-10 md:text-17xl lg:text-2xl mb-8 text-white md:ml-[-30px] lg:ml-[-150px] xl:ml-[-20px] xl:text-xl">Check out some of our past events and see for yourself the amazing experience</p>
      <div className="grid grid-cols-1 ml-16 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 lg:gap-[200px] lg:ml-[-150px] gap-20 xl:ml-[-20px] xl:gap-2">
        {images.map((image, index) => (
          <div key={index} className="text-left">
            {image.position === "above" && <p className=" text-29xl  md:text-17xl lg:text-3xl mb-2 text-white">{image.label}</p>}
            <div className="overflow-hidden rounded shadow border-2 border-black bg-white w-80 h-80 md:w-56 md:h-56 lg:w-64 lg:h-64">
              <img src={image.src} alt="Gallery" className="w-full h-full object-cover" />
            </div>
            {image.position === "below" && <p className="text-29xl sm:text-xl md:text-17xl lg:text-3xl mt-2 text-white">{image.label}</p>}
          </div>
        ))}
      </div>
      <div className="flex justify-end mt-10 mb-10 ">
        <button onClick={() => navigate('/archive')} className="bg-blue-500 text-17xl  md:text-xl lg:text-2xl text-black font-bold py-2 px-4 rounded-lg">View Archive</button>
      </div>
    </section>
  );
}

export default Gallery;
